import React, { createContext, useState } from "react";

export const CursorContext = createContext({
    cursorType: "",
    viewProject: false,
    cursorChangeHandler: (cursorType) => {},
    viewProjectHandler: (viewProject) => {}
});

const CursorContextProvider = (props) => {
    const [cursorType, setCursorType] = useState("")
    const [viewProject, setViewProject] = useState(false)
    const cursorChangeHandler = (cursorType) => {
        setCursorType(cursorType)
    };
    const viewProjectHandler = (viewProject) => {
        setViewProject(viewProject)
    }
    return (
        <CursorContext.Provider
            value={{
                cursorType: cursorType,
                viewProject: viewProject,
                cursorChangeHandler: cursorChangeHandler,
                viewProjectHandler: viewProjectHandler
            }}
        >
            {props.children}
        </CursorContext.Provider>
    );
};
export default CursorContextProvider;