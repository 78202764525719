import Errorbg from '../../sass/assets/walking.png';
function Error() {
	return (
		<main className='o-page-layout o-page-layout--error'>
			<div className='e-text e-text--off-center u-z-index-2'>
				<div className='e-text__content '>
					<h1 className='e-heading e-heading--sm e-reg'>Uh-oh.. Looks like me I’m out to walk my dog! </h1>
					<p>Please refresh the page or go back to the homepage.</p>
					<a className="c-btn" href="/">Back to home</a>
				</div>
			</div>
			<img src={Errorbg} alt="Illustration of a person and a dog"></img>
		</main>
	);
}

export default Error;