import Cv from '../../resources/cv.pdf'
import Casestudy from '../../resources/portfolio.pdf'

function Portfolio() {
	return (
		<main className='o-page-layout'>
			<div className='o-page-layout__component o-page-layout__component-background full-height'>
				<div className='o-page-layout__component-inner u-flex-center u-height-full'>
					<div className="e-text e-text-dark e-text--center">
						<div className="e-text__content">
							{/* Replace with H1 and add an additional paragraph below */}
							<h1 className="e-heading e-heading--sm e-reg">Hi, I am <span className='e-italic e-orange e-bold '>Ariane.</span>  <br></br>
								I love designing simple yet meaningful experiences that meet the needs of both businesses and users.
							</h1>
							<p>Download my CV and portfolio case studies below</p>
							<div className='u-flex-row'>
								<a
									className="c-btn c-btn--reg  c-btn--outline-light u-mright-sm u-mbot-sm"
									href={Cv} download="ariane-ibabao-cv.pdf">
									CV
								</a>
								<a
									className="c-btn c-btn--reg  c-btn--outline-light u-mright-sm u-mbot-sm"
									href={Casestudy} download="ariane-ibabao-portfolio.pdf">
									Portfolio
								</a>
							</div>
						</div>
					</div>
				</div>
				<div className='c-banner'>
					<p className='u-margin-none'>My website is currently under construction. Please bear with me ❤️</p>
				</div>
			</div>
		</main>
	);
}

export default Portfolio;