import Icons from '../../sass/assets/defs.svg';
import Cv from '../../resources/cv.pdf'
import React, { useState, useContext } from 'react';
import { CursorContext } from '../cursor/CursorContext';

function Header() {
	const [isNavDisplayed, setIsNavDisplayed] = useState(false);
	const { cursorChangeHandler, viewProjectHandler } = useContext(CursorContext)
	return (

		<header>
			<nav className={isNavDisplayed ? "c-nav c-nav--open" : "c-nav"}>
				<div className="c-nav__name">
					<h1 className="c-nav__name-ai">Ariane Ibabao</h1>
					<div className="c-nav__name-title">UX Designer</div>
				</div>
				<button
					onMouseEnter={() => cursorChangeHandler("hovered")}
					onMouseLeave={() => cursorChangeHandler("")}
					onClick={() => { setIsNavDisplayed(!isNavDisplayed) }}
					className="c-nav__menu-button">
					{isNavDisplayed ? "Close" : "Menu"}
				</button>
				<div className="c-nav-menu-full">
					<div className="c-nav-menu-full__content">

						<div className="c-nav-menu-full__info u-mbot-sm">
							<span className="c-nav-menu-full__info__email"> 
								a.m.d.ibabao@gmail.com
							</span>
							<div className="u-mtop-xs e-uppercase">London, United Kingdom</div>

							<div className='u-flex u-mtop-sm'>
								<a
									onMouseEnter={() => cursorChangeHandler("hovered")}
									onMouseLeave={() => cursorChangeHandler("")}
									className="c-btn-icon c-btn-icon--outline-light"
									href="https://www.linkedin.com/in/ariane-ibabao/">
									<svg className="c-ic c-ic--sm">
										<use xlinkHref={`${Icons}#ic-linkedin`}>
										</use>
									</svg>
								</a>
								<a
									onMouseEnter={() => cursorChangeHandler("hovered")}
									onMouseLeave={() => cursorChangeHandler("")}
									className="c-btn c-btn--reg  c-btn--outline-light"
									href={Cv} download="ariane-ibabao-cv.pdf">
										Download CV
									</a>
							</div>
						</div>

						<div className="c-nav-menu-full__nav">
							<ul className="c-nav-menu-full__nav-list">
								<li className="c-nav-menu-full__nav-list__content">
									<a
										onMouseEnter={() => { 
												cursorChangeHandler("hovered")
												viewProjectHandler(true)
											}
										}
										onMouseLeave={() => {
												cursorChangeHandler("")
												viewProjectHandler(false)
											}
										}

										className="c-nav-menu-full__nav-list__link" 
										href="/">
											My favourite UX projects
									</a>
								</li>
								<li className="c-nav-menu-full__nav-list__content">
									<a
										onMouseEnter={() => cursorChangeHandler("hovered")}
										onMouseLeave={() => cursorChangeHandler("")}
										className="c-nav-menu-full__nav-list__link" 
										href="/">
											About me
									</a>
								</li>
								<li className="c-nav-menu-full__nav-list__content">
									<a
										onMouseEnter={() => cursorChangeHandler("hovered")}
										onMouseLeave={() => cursorChangeHandler("")}
										className="c-nav-menu-full__nav-list__link"
										href="/">
											Other projects
									</a>
								</li>
								<li className="c-nav-menu-full__nav-list__content">
									<a
										onMouseEnter={() => cursorChangeHandler("hovered")}
										onMouseLeave={() => cursorChangeHandler("")}
										className="c-nav-menu-full__nav-list__link"
										href="/contact">
											Contact
									</a>
								</li>
								<li className="c-nav-menu-full__nav-list__content">
									<a
										onMouseEnter={() => cursorChangeHandler("hovered")}
										onMouseLeave={() => cursorChangeHandler("")}
										className="c-nav-menu-full__nav-list__link"
										href="/">
											All projects
									</a>
									<span className="u-mtop-xs">View all the projects I have worked on.</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</nav>

		</header >
	);
}

export default Header;