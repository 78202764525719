import React, { useEffect, useState, useContext } from "react";
import { CursorContext } from "./CursorContext";

const Cursor = () => {
    const { cursorType, viewProject } = useContext(CursorContext)
    const [cursorPosition, setCursorPosition] = useState({ x: null, y: null })
    useEffect(() => {
        const cursorMoveHandler = (event) => {
            const { clientX, clientY } = event
            setCursorPosition({ x: clientX, y: clientY })
        }
        document.addEventListener("mousemove", cursorMoveHandler)
        return () => {
            document.removeEventListener("mousemove", cursorMoveHandler)
        }
    }, [cursorPosition])
    return (
        <>
            <div
                className={`ring ${cursorType}`}
                style={{ left: `${cursorPosition.x}px`, top: `${cursorPosition.y}px` }}
            >
                {/* <div className="container projecttext e-text e-text-sm">View Project</div> */}
                {viewProject ? (<div className="container projecttext e-text e-text-sm">View Project</div>) : (<></>)}
            </div>
            <div
                className={`dot ${cursorType}`}
                style={{ left: `${cursorPosition.x}px`, top: `${cursorPosition.y}px` }}
            ></div>
        </>
    )
}
export default Cursor;