import React from 'react';
import ReactDOM from 'react-dom/client';
import {
	BrowserRouter as Router,
	Routes,
	Route,
} from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import Portfolio from './pages/portfolio/Portfolio';
import Windows from './pages/windows/Windows'
import About from './pages/about/About';
import Error from './pages/error/Error';
import Home from './pages/home/Home';
import Construction from './pages/construction/Construction';
import Header from './components/header/Header';
import Cursor from './components/cursor/Cursor';
import CursorContextProvider from './components/cursor/CursorContext';
import "./sass/site.scss";
const root = ReactDOM.createRoot(document.getElementById('root'));
const constructionMode = false
const portfolioMode = true
root.render(
	<CursorContextProvider>
		<React.StrictMode>
			<Cursor />
			{constructionMode ?
				<Router>
					<Routes>
						<Route path="/" element={<Construction />}></Route>
					</Routes>
				</Router>
				:
				portfolioMode
				?
				<Router>
					<Routes>
						<Route path="/" element={<Portfolio />}></Route>
					</Routes>
				</Router>
				:
				<Router>
					<Header />
					<Routes>
						<Route path="/about" element={<About />} />
						<Route path="/windows" element={<Windows />} />
						<Route path="/error" element={<Error />} />
						<Route path="/" element={<Home />} />
					</Routes>

				</Router>
			}
		</React.StrictMode>
	</CursorContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
